<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading || formLoading">
            <b-row>
              <b-col lg="12" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                      <b-row>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="org_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{$t('warehouse_config.organization')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="formData.org_id"
                                :options="orgList"
                                id="org_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Date" vid="check_date" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="check_date"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.date')}} <span class="text-danger">*</span>
                              </template>
                              <flat-pickr class="form-control"
                                v-model="formData.check_date"
                                :placeholder="$t('globalTrans.select_date')"
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></flat-pickr>
                              <div class="invalid-feedback" :class="errors[0] ? 'd-block': ''">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Accession Number" vid="accession_number" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="accession_number"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('germplasm.access_no')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                id="org_name"
                                v-model="formData.accession_number"
                                :state="errors[0] ? false : (valid ? true : null)"
                                @keyup="getCollectionData()"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="GID NO." vid="gid_no" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="gid_no"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germplasm.gid_no')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  id="gid_no"
                                  v-model="formData.gid_no"
                                  @change="getCollectionData()"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <!-- <b-overlay :show="formLoading"> -->
                      <b-row>
                        <b-col lg="6" sm="12">
                            <ValidationProvider name="Crop Type"  vid="crop_type_id">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="crop_type_id"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germplasm.crop_type')}}
                              </template>
                              <b-form-input
                                id="crop_type_id"
                                readonly
                                v-model="formData.crop_type_name"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Crop Name"  vid="crop_name_id">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="crop_name_id"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germplasm.crop')}}
                              </template>
                              <b-form-input
                                id="crop_type_id"
                                readonly
                                v-model="formData.crop_name"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Scientific Name" vid="scientific">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="scientific"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germplasm.scientific')}}
                              </template>
                              <b-form-input
                                id="scientific"
                                readonly
                                v-model="formData.scientific_name"
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="English Name" vid="english">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="english"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germplasm.english')}}
                              </template>
                              <b-form-input
                                id="english"
                                readonly
                                v-model="formData.english_name"
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Local Name" vid="local_name">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="local_name"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germplasm.local_name')}}
                              </template>
                              <b-form-input
                                id="local_name"
                                readonly
                                v-model="formData.local_name"
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                        <ValidationProvider name="Cultivar Name"  vid="cultivar_name">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="cultivar_name"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('germplasm.cultivar_name')}} <span class="text-danger"></span>
                              </template>
                              <b-form-input
                                id="local_name"
                                readonly
                                v-model="formData.cultivar_name"
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <!-- </b-overlay> -->
                      <b-row>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Details (En)" vid="details" rules="required">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="details"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{$t('germplasm.check_datails_en')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-textarea
                              id="detail"
                              v-model="formData.details"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-textarea>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Details (BN)" vid="details_bn" rules="required">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="details_bn"
                            slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('germplasm.check_datails_bn')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-textarea
                              id="details_bn"
                              v-model="formData.details_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-textarea>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                        </b-col>
                      </b-row>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                          &nbsp;
                          <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
              </b-col>
            </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { checkStatusStore, checkStatusUpdate, checkStatusByCollectionNew } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    this.formData.org_id = this.$store.state.dataFilters.orgId
    if (this.id) {
      const tmp = this.getFormData()
      this.formData = tmp
      this.getCollectionData()
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        org_id: 0,
        check_date: '',
        crop_name: '',
        crop_type_name: '',
        accession_number: '',
        gid_no: '',
        details: '',
        details_bn: '',
        cultivar_name: '',
        local_name: '',
        english_name: '',
        scientific_name: '',
        characterization_details: '',
        characterization_type_name: ''
      },
      // CropNameList: [],
      CropNameListCustom: [],
      CropTypeListCustom: [],
      formLoading: false
    }
  },
  computed: {
    CropTypeList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.gpCropTypeList.filter(item => item.status === 1)
    },
    CropNameList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.gpCropNameList.filter(item => item.status === 1)
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    loading () {
      return this.$store.state.commonObj.loading
    }
  },
  watch: {
    // 'formData.crop_type_id': function (newVal, oldVal) {
    //   this.CropNameList = this.getCropNameList(newVal)
    // }
  },
  methods: {
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async createData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      if (this.formData.id) {
        result = await RestApi.putData(seedFertilizerServiceBaseUrl, `${checkStatusUpdate}/${this.id}`, this.formData)
      } else {
        result = await RestApi.postData(seedFertilizerServiceBaseUrl, checkStatusStore, this.formData)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('SeedsFertilizer/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    getCropNameList (cropTypeId = null) {
      const cropNameList = this.$store.state.SeedsFertilizer.commonObj.gpCropNameList.filter(item => item.status === 1)
      if (cropTypeId) {
        return cropNameList.filter(union => union.cropTypeId === cropTypeId)
      }
      return cropNameList
    },
    async getCollectionData () {
      if (!(this.formData.accession_number && this.formData.gid_no)) {
        return false
      }
      this.formLoading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      const params = Object.assign({}, {
        accession_number: this.formData.accession_number,
        gid_no: this.formData.gid_no
      })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      await RestApi.getData(seedFertilizerServiceBaseUrl, checkStatusByCollectionNew, params).then(response => {
          if (response.success) {
              this.getCustomDataList(response.data)
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
      this.formLoading = false
      this.$store.dispatch('mutateCommonProperties', { loading: false })
    },
    getCustomDataList (data) {
      this.formData.crop_type_id = data.crop_type_id
      this.formData.crop_name_id = data.crop_name_id
      this.formData.local_name = this.$i18n.locale === 'en' ? data.local_name : data.local_name_bn
      this.formData.cultivar_name = this.$i18n.locale === 'en' ? data.cultivar_name : data.cultivar_name_bn
      this.formData.crop_type_name = this.$i18n.locale === 'en' ? data.crop_type : data.crop_type_bn
      this.formData.crop_name = this.$i18n.locale === 'en' ? data.crop_name : data.crop_name_bn
      this.formData.english_name = this.$i18n.locale === 'en' ? data.english_name : data.english_name_bn
      this.formData.scientific_name = this.$i18n.locale === 'en' ? data.scientific_name : data.scientific_name_bn
      this.formData.characterization_type_name = this.$i18n.locale === 'en' ? data.characterization_type : data.characterization_type_bn
      this.formData.characterization_details = this.$i18n.locale === 'en' ? data.characterization_details : data.characterization_details_bn
    }
  }
}
</script>
